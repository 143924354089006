import { useGetSensorAdjustmentQuery } from "../../store/api";
import { Sensor } from "../../store/types";
import { SensorValueAdjustExisting } from "./SensorValueAdjustExisting";
import { SensorValueAdjustNew } from "./SensorValueAdjustNew";
import { Modal, Typography } from "antd";

export const SensorValueAdjust = ({ sensor, onClose }: { sensor: Sensor; onClose: () => void }) => {
  const sensorAdjustment = useGetSensorAdjustmentQuery({ deviceId: sensor.deviceId, key: sensor.key });

  // @ts-ignore status exists
  if (sensorAdjustment.error?.status === 409) {
    return <SensorValueAdjustNew sensor={sensor} onClose={onClose} />;
  }
  if (!sensorAdjustment.error) {
    return <SensorValueAdjustExisting sensor={sensor} onClose={onClose} />;
  }
  return (
    <Modal onCancel={onClose} onClose={onClose} open width={600}>
      <Typography.Title>Virhetilanne</Typography.Title>
    </Modal>
  );
};
