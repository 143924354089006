import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  User,
  Organization,
  Device,
  Sensor,
  PermissionsResponse,
  SetUsersForDeviceRequest,
  SetUsersForOrganizationRequest,
  SetDevicesForOrganizationRequest,
  DeviceType,
  Session,
  Setting,
  SettingType,
  SensorAdjustValue,
  SetSensorAdjustValueRequest,
} from "./types";
import { baseUrl } from "../constants";

// https://redux-toolkit.js.org/rtk-query/overview#create-an-api-slice
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl,
    credentials: "include",
  }),
  tagTypes: [
    "CurrentUser",
    "Users",
    "Devices",
    "DeviceTypes",
    "Sensors",
    "SensorAdjust",
    "Permissions",
    "Organizations",
    "Sessions",
    "Settings",
  ],
  endpoints: (builder) => ({
    // Login
    login: builder.mutation<User, { username: string; password: string }>({
      query: (login) => ({
        url: "/api/login",
        method: "POST",
        body: login,
      }),
      invalidatesTags: ["CurrentUser"],
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/api/logout",
        method: "POST",
      }),
    }),

    // Current User
    getCurrentUser: builder.query<User, void>({
      query: () => "/api/user",
      providesTags: ["CurrentUser"],
    }),
    editCurrentUser: builder.mutation<User, Partial<User>>({
      query: (user) => ({ url: "/api/user", method: "PATCH", body: user }),
      invalidatesTags: ["CurrentUser"],
    }),
    setCurrentUserPassword: builder.mutation<void, { newPassword: string; newPassword2: string }>({
      query: (password) => ({
        url: "/api/user/set_password",
        method: "PUT",
        body: password,
        responseHandler: "text",
      }),
    }),

    // Users
    getUsers: builder.query<User[], void>({
      query: () => "/api/users",
      providesTags: ["Users"],
    }),
    addUser: builder.mutation<User, User>({
      query: (user) => ({ url: "/api/beta/users", method: "POST", body: user }),
      invalidatesTags: ["Users", "CurrentUser"],
    }),
    editUser: builder.mutation<User, Partial<User>>({
      query: (user) => ({
        url: `/api/users/${user.userId}`,
        method: "PATCH",
        body: user,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation<User, number>({
      query: (userId) => ({
        url: `/api/users/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    generatePassword: builder.mutation<void, User>({
      query: (user) => ({
        url: `/api/users/${user.userId}/generate_password`,
        method: "POST",
      }),
    }),

    // User verification
    startEmailVerification: builder.mutation<void, { email: string }>({
      query: (data) => ({ url: "/api/user/email/start", method: "POST", body: data }),
    }),
    completeEmailVerification: builder.mutation<void, { code: string }>({
      query: (data) => ({ url: "/api/user/email/complete", method: "POST", body: data }),
      invalidatesTags: ["Users", "CurrentUser"],
    }),
    startPhoneNumberVerification: builder.mutation<void, { phone: string }>({
      query: (data) => ({ url: "/api/user/phone/start", method: "POST", body: data }),
    }),
    completePhoneNumberVerification: builder.mutation<void, { code: string }>({
      query: (data) => ({ url: "/api/user/phone/complete", method: "POST", body: data }),
      invalidatesTags: ["Users", "CurrentUser"],
    }),

    // Organizations
    getOrganizations: builder.query<Organization[], void>({
      query: () => "/api/organizations",
      providesTags: ["Organizations"],
    }),
    addOrganization: builder.mutation<Organization, Organization>({
      query: (org) => ({
        url: "/api/organizations",
        method: "POST",
        body: org,
      }),
      invalidatesTags: ["Organizations"],
    }),
    editOrganization: builder.mutation<Organization, Organization>({
      query: (org) => ({
        url: `/api/organizations/${org.organizationId}`,
        method: "PUT",
        body: org,
      }),
      invalidatesTags: ["Organizations"],
    }),

    // Devices
    getDevices: builder.query<Device[], void>({
      query: () => "/api/devices",
      providesTags: ["Devices"],
    }),
    addDevice: builder.mutation<void, number>({
      query: (amount) => ({
        url: "/api/devices",
        method: "POST",
        body: { amount },
      }),
      invalidatesTags: ["Devices"],
    }),
    editDevice: builder.mutation<Device, Device>({
      query: (device) => ({
        url: `/api/devices/${device.deviceId}`,
        method: "PATCH",
        body: device,
      }),
      invalidatesTags: ["Devices"],
    }),

    // DeviceTypes
    getDeviceTypes: builder.query<DeviceType[], void>({
      query: () => "/api/deviceTypes",
      providesTags: ["DeviceTypes"],
    }),
    addDeviceType: builder.mutation<void, string>({
      query: (name) => ({
        url: "/api/deviceTypes",
        method: "POST",
        body: { name },
      }),
      invalidatesTags: ["DeviceTypes"],
    }),
    editDeviceType: builder.mutation<void, { deviceTypeId: number; name: string }>({
      query: ({ deviceTypeId, name }) => ({
        url: `/api/deviceTypes/${deviceTypeId}`,
        method: "PATCH",
        body: { name },
      }),
      invalidatesTags: ["Devices", "DeviceTypes"],
    }),

    // Sensors
    getSensors: builder.query<Sensor[], void>({
      query: () => "/api/keys",
      providesTags: ["Sensors"],
    }),
    reloadSensor: builder.mutation<Sensor, { deviceId: string; key: string }>({
      query: ({ deviceId, key }) => `/api/devices/${deviceId}/keys/${key}`,
      async onQueryStarted(sensor, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSensor } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData("getSensors", undefined, (draft) => {
              return draft.map((s) => {
                if (s.deviceId === updatedSensor.deviceId && s.key === updatedSensor.key) {
                  return updatedSensor;
                } else {
                  return s;
                }
              });
            })
          );
        } catch {}
      },
    }),
    editSensor: builder.mutation<Sensor, Sensor>({
      query: (sensor) => ({
        url: `/api/devices/${sensor.deviceId}/keys/${sensor.key}`,
        method: "PATCH",
        body: sensor,
      }),
      async onQueryStarted(sensor, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSensor } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData("getSensors", undefined, (draft) => {
              return draft.map((s) => {
                if (s.deviceId === updatedSensor.deviceId && s.key === updatedSensor.key) {
                  return updatedSensor;
                } else {
                  return s;
                }
              });
            })
          );
        } catch {}
      },
    }),

    // Sensor Adjust
    getSensorAdjustment: builder.query<SensorAdjustValue | undefined, { deviceId: string; key: string }>({
      query: ({ deviceId, key }) => `/api/devices/${deviceId}/sensors/${key}/adjust`,
      providesTags: ["SensorAdjust"],
    }),
    setSensorAdjustment: builder.mutation<void, SetSensorAdjustValueRequest>({
      query: ({ deviceId, key, timestamp, value }) => ({
        url: `/api/devices/${deviceId}/sensors/${key}/adjust`,
        method: "PUT",
        body: { timestamp, value },
      }),
      invalidatesTags: ["SensorAdjust"],
    }),
    cancelSensorAdjustment: builder.mutation<void, { deviceId: string; key: string }>({
      query: ({ deviceId, key }) => ({
        url: `/api/devices/${deviceId}/sensors/${key}/adjust`,
        method: "DELETE",
      }),
      invalidatesTags: ["SensorAdjust"],
    }),

    // Permissions
    getPermissions: builder.query<PermissionsResponse, void>({
      query: () => "/api/beta/permissions",
      providesTags: ["Permissions"],
    }),
    setUsersForDevice: builder.mutation<void, SetUsersForDeviceRequest>({
      query: ({ deviceId, userIds }) => ({
        url: `/api/beta/permissions/device/${deviceId}/users`,
        method: "PUT",
        body: userIds,
      }),
      invalidatesTags: ["Permissions"],
    }),
    setUsersForOrganization: builder.mutation<void, SetUsersForOrganizationRequest>({
      query: ({ organizationId, userIds }) => ({
        url: `/api/beta/permissions/organization/${organizationId}/users`,
        method: "PUT",
        body: userIds,
      }),
      invalidatesTags: ["Permissions"],
    }),
    setDevicesForOrganization: builder.mutation<void, SetDevicesForOrganizationRequest>({
      query: ({ organizationId, deviceIds }) => ({
        url: `/api/beta/permissions/organization/${organizationId}/devices`,
        method: "PUT",
        body: deviceIds,
      }),
      invalidatesTags: ["Permissions", "Organizations"],
    }),

    // Sessions
    getSessions: builder.query<Session[], { deviceId: string; key: string }>({
      query: ({ deviceId, key }) => `/api/sessions/devices/${deviceId}/keys/${key}`,
      providesTags: ["Sessions"],
    }),
    deleteSession: builder.mutation<void, { keyId: number; sessionId: number }>({
      query: (data) => ({
        url: `/api/sessions/delete`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Sessions"],
    }),
    multiplySession: builder.mutation<void, { keyId: number; sessionId: number; factor: number }>({
      query: (data) => ({
        url: `/api/sessions/multiply`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Sessions"],
    }),

    // Settings
    getSettings: builder.query<Setting[], { deviceId: string }>({
      query: ({ deviceId }) => `/api/devices/${deviceId}/settings`,
      providesTags: ["Settings"],
    }),
    addSetting: builder.mutation<void, { deviceId: string; name: string; value: any; settingType: SettingType }>({
      query: ({ deviceId, name, value, settingType }) => ({
        url: `/api/devices/${deviceId}/settings`,
        method: "POST",
        body: { name, value, settingType },
      }),
      invalidatesTags: ["Settings"],
    }),
    editSetting: builder.mutation<void, { deviceId: string; name: string; value: any }>({
      query: ({ deviceId, name, value }) => ({
        url: `/api/devices/${deviceId}/settings/${name}`,
        method: "PUT",
        body: { value },
      }),
      invalidatesTags: ["Settings"],
    }),
    deleteSetting: builder.mutation<void, { deviceId: string; name: string }>({
      query: ({ deviceId, name }) => ({
        url: `/api/devices/${deviceId}/settings/${name}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Settings"],
    }),
  }),
});

// autogenerated hooks
export const {
  useLoginMutation,
  useLogoutMutation,

  useLazyGetCurrentUserQuery,
  useGetCurrentUserQuery,
  useEditCurrentUserMutation,
  useSetCurrentUserPasswordMutation,

  useGetUsersQuery,
  useAddUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useGeneratePasswordMutation,

  useStartEmailVerificationMutation,
  useCompleteEmailVerificationMutation,
  useStartPhoneNumberVerificationMutation,
  useCompletePhoneNumberVerificationMutation,

  useGetOrganizationsQuery,
  useAddOrganizationMutation,
  useEditOrganizationMutation,

  useGetDevicesQuery,
  useAddDeviceMutation,
  useEditDeviceMutation,

  useGetDeviceTypesQuery,
  useAddDeviceTypeMutation,
  useEditDeviceTypeMutation,

  useGetSensorsQuery,
  useReloadSensorMutation,
  useEditSensorMutation,

  useGetSensorAdjustmentQuery,
  useSetSensorAdjustmentMutation,
  useCancelSensorAdjustmentMutation,

  useGetPermissionsQuery,
  useSetUsersForDeviceMutation,
  useSetUsersForOrganizationMutation,
  useSetDevicesForOrganizationMutation,

  useGetSessionsQuery,
  useDeleteSessionMutation,
  useMultiplySessionMutation,

  useGetSettingsQuery,
  useAddSettingMutation,
  useEditSettingMutation,
  useDeleteSettingMutation,
} = api;
