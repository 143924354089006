import { DateTime, Interval } from "luxon";

export interface User {
  userId: number;
  userName: string | null;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  lastLogin: string | null;
  isAdmin: boolean;
  description: string;
  created: string;
}

export enum KeyType {
  Temperature = 1,
  Water = 2,
  Voltage = 4,
  WaterLevel = 7,
  Energy = 8,
  Pressure = 11,
  Accumulator = 12,
  TimeSeconds = 13,
}

export enum KeyType2 {
  TimeSeries = 1,
  Accumulating = 2,
}

interface SensorAllow {
  setMeterValue: boolean;
}

export interface Sensor {
  deviceId: string;
  key: string;
  description: string;
  latestUpdate: string | null;
  latestValue: number | null;
  latestValueRaw: number | null;
  keyType: number | null;
  keyType2: KeyType2 | null;
  order: number | null;
  sumTerm: number;
  factorTerm: number;
  latestInterval: number | null;
  displayOnFrontPage: boolean;
  smartAlert: boolean;
  meterSerialNumber: string;
  allow: SensorAllow;
}

export interface DeviceType {
  deviceTypeId: number;
  name: string;
}

export interface Device {
  deviceId: string;
  name: string;
  sensors: Sensor[];
  initialized: boolean;
  created: string;
  address: string;
  locality: string;
  address2: string;
  description: string;
  latestConnection: string | null;
  deviceType: DeviceType | null;
  siteId: string;
  notes: string;
  accessLevel: "device" | "organization" | "admin";
}

export enum ValueEnum {
  PLAIN = "plain",
  AVG = "avg",
  RAW = "raw",
  LMIN = "lmin",
}

// Value in serializable form which can be saved into redux
export interface Value {
  _type: ValueEnum;
  start: string;
  end: string;

  // same as above but with milliseconds to optimize performance
  startMs: number;
  endMs: number;
  timestampMs: number;

  value: number | null;
  avg: number | null;
  min: number | null;
  max: number | null;
  samples: number | null;
  rawValue: number | null;
}

export type ValueTypes = PlainValue | AvgValue | RawValue | LMinValue;

export type PlainValue = {
  _type: ValueEnum.PLAIN;
  start: DateTime;
  end: DateTime;
  timestamp: DateTime;
  startMs: number;
  endMs: number;
  timestampMs: number;
  interval: Interval;
  value: number;
};

export interface AvgValue {
  _type: ValueEnum.AVG;
  start: DateTime;
  end: DateTime;
  timestamp: DateTime;
  startMs: number;
  endMs: number;
  timestampMs: number;
  interval: Interval;
  avg: number;
  min: number;
  max: number;
  samples: number;
}

export interface RawValue {
  _type: ValueEnum.RAW;
  start: DateTime;
  end: DateTime;
  timestamp: DateTime;
  startMs: number;
  endMs: number;
  timestampMs: number;
  interval: Interval;
  value: number;
}

export interface LMinValue {
  _type: ValueEnum.LMIN;
  start: DateTime;
  end: DateTime; // The end timestamp represents the moment for rawValue
  timestamp: DateTime;
  startMs: number;
  endMs: number;
  timestampMs: number;
  interval: Interval;
  value: number;
  rawValue: number;
}

export enum Level {
  year = "year",
  month = "month",
  day = "day",
  hour = "hour",
  minute = "minute",
  raw = "raw",
}

export enum ViewType {
  Auto = "auto", // TODO refactor this with more thoughtful settings
  Min = "min",
}

export interface Organization {
  organizationId: string;
  typeId: number;
  name: string;
  businessId: string | null;
  description: string;
  address1: string;
  address2: string;
  devices: string[];
}

export interface UserDevicePermission {
  userId: number;
  deviceId: string;
}

export interface OrganizationUserPermission {
  organizationId: string;
  userId: number;
}

export interface OrganizationDevicePermission {
  organizationId: string;
  deviceId: string;
}

export interface PermissionsResponse {
  userDevicePermissions: UserDevicePermission[];
  organizationUserPermissions: OrganizationUserPermission[];
  organizationDevicePermissions: OrganizationDevicePermission[];
}

export interface SetUsersForDeviceRequest {
  deviceId: string;
  userIds: number[];
}

export interface SetUsersForOrganizationRequest {
  organizationId: string;
  userIds: number[];
}

export interface SetDevicesForOrganizationRequest {
  organizationId: string;
  deviceIds: string[];
}

export interface Session {
  deviceId: string;
  key: string;
  keyId: number;
  sessionId: number;
  startTime: string;
  starValue: number;
  endTime: string;
  endValue: number;
  count: number;
}

export enum SettingType {
  SettingTypeString = 1,
  SettingTypeInteger = 2,
}

export const SETTING_TYPE_NAME = {
  [SettingType.SettingTypeString]: "Merkkijono",
  [SettingType.SettingTypeInteger]: "Kokonaisluku",
};

export const SETTING_TYPE_DEFAULT = {
  [SettingType.SettingTypeInteger]: 0,
  [SettingType.SettingTypeString]: "",
};

export interface Setting {
  deviceId: string;
  name: string;
  updatedTime: string;
  deviceReadTime: string | undefined;
  settingType: SettingType;
  value: any;
}

export interface SensorAdjustValue {
  timestamp: string;
  value: number;
}

export interface SetSensorAdjustValueRequest {
  deviceId: string;
  key: string;
  timestamp: string;
  value: number;
}
