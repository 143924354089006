import { configureStore } from "@reduxjs/toolkit";
import { api } from "./api";

import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { message } from "antd";
import { valueReducer } from "./values/reducer";

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (!isRejectedWithValue(action)) {
    return next(action);
  }

  if (action?.payload?.status === 409) {
    return next(action);
  }

  switch (action.meta.arg.endpointName) {
    case "login":
      console.log("invalid login");
      break;
    case "getCurrentUser":
      if (window.location.pathname === "/login") {
        return;
      }
      window.location.pathname = "/login";
      break;
    default:
      console.error("API Error", action);
      message.error("Jotain meni pieleen", 5);
  }
  return next(action);
};

export const store = configureStore({
  devTools: { maxAge: 500 },
  reducer: {
    [api.reducerPath]: api.reducer,
    values: valueReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false }).concat(api.middleware).concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
