import { ContentCard } from "../../utils/styled";
import { Button, message } from "antd";
import { SensorConfigurationCardForm } from "./SensorConfigurationCardForm";
import { Sensor } from "../../store/types";
import { useCallback, useState } from "react";
import { useEditSensorMutation } from "../../store/api";
import { EditOutlined } from "@ant-design/icons";
import { SensorValueAdjust } from "./SensorValueAdjust";

export const SensorConfigurationCard = ({ sensor, mode }: { sensor: Sensor; mode: "admin" | "user" }) => {
  const [editSensor] = useEditSensorMutation();
  const [dirty, setDirty] = useState<Sensor>();
  const [adjustMeterValueModal, setAdjustMeterValueModal] = useState(false);

  // Use this to reset the internal state of the form on cancel
  const [reset, setReset] = useState(0);

  const handleReset = useCallback(() => {
    setDirty(undefined);
    setReset((prev) => prev + 1);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!dirty) {
      return;
    }
    try {
      await editSensor(dirty).unwrap();
      handleReset();
      message.success("Sensori tallennettu");
    } catch (err) {
      console.error("Error saving sensor", err);
    }
  }, [dirty, editSensor, handleReset]);

  return (
    <ContentCard
      title={sensor.key}
      extra={
        <span style={{ display: "flex", gap: "10px" }}>
          {sensor.allow.setMeterValue && (
            <Button type={"text"} icon={<EditOutlined />} onClick={() => setAdjustMeterValueModal(true)}>
              Aseta mittarilukema
            </Button>
          )}
          <Button disabled={!dirty} onClick={handleReset}>
            Peruuta
          </Button>
          <Button disabled={!dirty} onClick={handleSubmit} type="primary" htmlType="submit">
            Tallenna
          </Button>
        </span>
      }
    >
      <SensorConfigurationCardForm key={reset} sensor={dirty || sensor} onChange={setDirty} mode={mode} />
      {adjustMeterValueModal && <SensorValueAdjust sensor={sensor} onClose={() => setAdjustMeterValueModal(false)} />}
    </ContentCard>
  );
};
