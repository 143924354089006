import { Sensor } from "../../store/types";
import { DatePicker, Form, Input, Modal, TimePicker, Typography } from "antd";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useSetSensorAdjustmentMutation } from "../../store/api";

export const SensorValueAdjustNew = ({ sensor, onClose }: { sensor: Sensor; onClose: () => void }) => {
  const [setSensorAdjustment] = useSetSensorAdjustmentMutation();
  const now = useMemo(() => dayjs(), []);

  const [date, setDate] = useState<dayjs.Dayjs>(now.startOf("day"));
  const [time, setTime] = useState<dayjs.Dayjs>(now);
  const [value, setValue] = useState("");

  const handleOk = useCallback(async () => {
    const timestamp = date.add(time.hour(), "hour").add(time.minute(), "minute").add(time.second(), "second");
    const v = parseInt(value);
    if (!Number.isInteger(v)) {
      return;
    }

    try {
      await setSensorAdjustment({
        deviceId: sensor.deviceId,
        key: sensor.key,
        timestamp: timestamp.toJSON(),
        value: v,
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  }, [date, sensor.deviceId, sensor.key, setSensorAdjustment, time, value]);

  return (
    <Modal onCancel={onClose} onClose={onClose} onOk={handleOk} open width={600}>
      <Layout>
        <div>
          <Typography.Title level={5}>
            Aseta tämän hetken mittarilukema, tai anna lukema menneisyydessä.
          </Typography.Title>
          <Typography.Paragraph>
            Jos esimerkiksi kirjasit ylös paljonko lukema oli eilen klo 12, niin voit syöttää sen hetken lukeman nyt
            jälkikäteen.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Muutos koskee vain uusia lukemia. Muutos ei tule voimaan välittömästi, vaan vasta sen jälkeen kun laite on
            ottanut yhteyden palvelimeen.
          </Typography.Paragraph>
        </div>

        <Form labelCol={{ span: 5 }}>
          <Form.Item label={"Päivämäärä"}>
            <DatePicker
              style={{ width: "200px" }}
              defaultValue={date}
              onChange={(date) => {
                setDate(date);
              }}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item label={"Kellonaika"}>
            <TimePicker
              style={{ width: "200px" }}
              defaultValue={time}
              onChange={(date) => setTime(date)}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item label={"Mittarilukema"}>
            <Input
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              style={{ width: "200px" }}
            />
          </Form.Item>
        </Form>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
