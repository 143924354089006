import { useGetCurrentUserQuery, useGetDevicesQuery, useGetSensorsQuery } from "../../../store/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Interval } from "luxon";
import { Level, ViewType } from "../../../store/types";
import { BreadcrumbInCardTitle, ContentCard, LayoutContent } from "../../../utils/styled";
import { Button } from "antd";
import { useMemo } from "react";
import styled from "styled-components";
import { DeviceViewDescription } from "./DeviceViewDescription";
import { DEMO_USER_ID } from "../../../components/constants";
import { getDeviceName } from "../../../components/devices/utils";
import { DeviceViewSummaryCard } from "./DeviceViewSummaryCard";
import { GraphStateProvider } from "../../../components/Graph/atoms/provider";
import { DeviceViewGraphs } from "./DeviceViewGraphs";

export const DeviceView = ({
  interval,
  level,
  viewType,
}: {
  interval: Interval;
  level: Level;
  viewType: ViewType;
}): JSX.Element | null => {
  const { deviceId } = useParams();
  const navigate = useNavigate();

  const currentUser = useGetCurrentUserQuery();
  const sensors = useGetSensorsQuery();
  const devices = useGetDevicesQuery();
  const device = devices.data?.find((d) => d.deviceId === deviceId);
  const breadcrumbs = useMemo(
    () => [
      {
        title: <Link to={"../devices"}>Mittarit</Link>,
      },
      { title: getDeviceName(device) },
    ],
    [device]
  );

  if (currentUser.isLoading || sensors.isLoading || !sensors.data || devices.isLoading) {
    return null;
  }

  const visibleSensors = sensors.data.filter((s) => s.deviceId === deviceId && s.keyType && s.keyType2);
  const canModify =
    currentUser.data?.userId !== DEMO_USER_ID &&
    (device?.accessLevel === "organization" || device?.accessLevel === "admin");

  return (
    <LayoutContent>
      <ContentCard
        title={<BreadcrumbInCardTitle items={breadcrumbs} />}
        extra={
          <TitleLayout>
            {canModify && <Button onClick={() => navigate(`${deviceId}/settings`)}>Asetukset</Button>}
          </TitleLayout>
        }
      >
        {device && <DeviceViewDescription device={device} />}
      </ContentCard>
      <SummaryLayout>
        {visibleSensors.map((sensor) => (
          <DeviceViewSummaryCard key={sensor.deviceId + sensor.key} sensor={sensor} />
        ))}
      </SummaryLayout>
      <GraphStateProvider interval={interval} level={level}>
        <DeviceViewGraphs visibleSensors={visibleSensors} viewType={viewType} />
      </GraphStateProvider>
    </LayoutContent>
  );
};

const SummaryLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`;

const TitleLayout = styled.div`
  @media (width < 500px) {
    display: none;
  }
  display: flex;
  align-items: center;
  gap: 10px;
`;
