import { Button, Checkbox, Segmented, Tooltip, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Duration, Interval } from "luxon";
import { Level, ViewType } from "../../../store/types";
import styled from "styled-components";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useLevels } from "../../../components/Graph/useLevels";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { AnimationFunc } from "../../../components/Graph/useAnimateAll";
import { useControls } from "../../../components/Graph/useControls";
import { Position } from "../../../components/Graph/types";
import { isMobileOrTablet } from "../../../utils/mobile";

const { Text } = Typography;

const humanizeDuration = require("humanize-duration");

export const humanizePrecision = (level: Level) => {
  if (level === Level.year) {
    return (
      <>
        <strong>vuoden</strong> tarkkuudella
      </>
    );
  }
  if (level === Level.month) {
    return (
      <>
        <strong>kuukauden</strong> tarkkuudella
      </>
    );
  }
  if (level === Level.day) {
    return (
      <>
        <strong>päivän</strong> tarkkuudella
      </>
    );
  }
  if (level === Level.hour) {
    return (
      <>
        <strong>tunnin</strong> tarkkuudella
      </>
    );
  }
  if (level === Level.minute) {
    return (
      <>
        <strong>minuutin</strong> tarkkuudella
      </>
    );
  }
  if (level === Level.raw) {
    return (
      <>
        <strong>parhaalla</strong> tarkkuudella
      </>
    );
  }
  return;
};

const getDurationOption = (duration: Duration) => {
  return humanizeDuration(duration.toMillis(), {
    language: "shortFi",
    largest: 1,
    units: ["y", "mo", "w", "d", "h", "m"],
    round: true,
    spacer: "",
    languages: {
      shortFi: {
        y: () => "v",
        mo: () => "kk",
        w: () => "vko",
        d: () => "pv",
        h: () => "t",
        m: () => "m",
        s: () => "s",
      },
    },
  });
};

export const DeviceViewIntervalSelect = ({
  onSetStaticPosition,
  staticPosition,
  viewType,
  setViewType,
  lockToNow,
  setLockToNow,
  onAnimate,
}: {
  onSetStaticPosition: (position: Position) => void;
  staticPosition: Position;
  viewType: ViewType;
  setViewType: (viewType: ViewType) => void;
  lockToNow: boolean;
  setLockToNow: (value: boolean) => void;
  onAnimate: AnimationFunc;
}): JSX.Element | null => {
  const { level, interval } = staticPosition;

  const { handlePanLeft, handlePanRight, handleAnimate } = useControls(
    onAnimate,
    staticPosition.interval,
    staticPosition.level,
    lockToNow,
    setLockToNow
  );

  const { findClosestIdx, availableLevels, alignToNow } = useLevels(lockToNow, setLockToNow);
  const [currentIdx, setCurrentIdx] = useState(() => findClosestIdx(interval.toDuration()));
  useEffect(() => setCurrentIdx(findClosestIdx(interval.toDuration())), [findClosestIdx, interval]);

  const options = availableLevels.map((l, i) => ({
    value: i,
    label: <strong>{getDurationOption(l.duration)}</strong>,
  }));

  const narrowOptions = availableLevels.map((l, i) => ({
    value: i,
    label: getDurationOption(l.duration),
  }));

  const handleSelect = useCallback(
    (value: number) => {
      const duration = availableLevels[value].duration;
      const center = interval.start.plus(interval.end.diff(interval.start).milliseconds / 2);
      const half = duration.toMillis() / 2;
      const start = center.minus(half);
      const end = center.plus(half);
      const newInterval = lockToNow
        ? alignToNow(Interval.fromDateTimes(start, end), availableLevels[value].level)
        : Interval.fromDateTimes(start, end);
      handleAnimate(availableLevels[value].level, newInterval);
      setCurrentIdx(value);
    },
    [alignToNow, availableLevels, handleAnimate, interval, lockToNow]
  );

  const handleViewTypeSelect = useCallback(
    (e: CheckboxChangeEvent) => {
      setViewType(e.target.checked ? ViewType.Min : ViewType.Auto);
    },
    [setViewType]
  );

  // Mobile chrome would activate this on click, which is disturbing
  const enableTooltip = !isMobileOrTablet();

  return (
    <Size>
      <MoreThan460>
        <FlexWrap>
          <MoreThan520>
            <Button shape="circle" icon={<LeftOutlined />} onClick={handlePanLeft} />
          </MoreThan520>
          <IntervalSelect>
            <Segmented value={currentIdx} options={options} onChange={handleSelect} />
            <UnitSubtitle>
              <Text type={"secondary"}>{humanizePrecision(availableLevels[currentIdx].level)}</Text>
            </UnitSubtitle>
          </IntervalSelect>
          <MoreThan520>
            <Button shape="circle" icon={<RightOutlined />} onClick={handlePanRight} />
          </MoreThan520>
          <Checkbox disabled={level !== Level.day} checked={viewType === ViewType.Min} onChange={handleViewTypeSelect}>
            Päivän pienin tuntikulutus
          </Checkbox>
          <Tooltip
            title={enableTooltip && "Lukitse kuvaaja nykyhetkeen. Lukemat päivittyvät joka tapauksessa automaattisesti"}
          >
            <Checkbox checked={lockToNow} onChange={() => setLockToNow(!lockToNow)}>
              Nyt
            </Checkbox>
          </Tooltip>
        </FlexWrap>
      </MoreThan460>
      <LessThan460>
        <IntervalSelectRow>
          <IntervalSelect>
            <Segmented block size={"small"} value={currentIdx} options={narrowOptions} onChange={handleSelect} />
            <UnitSubtitle>
              <Text type={"secondary"}>{humanizePrecision(availableLevels[currentIdx].level)}</Text>
            </UnitSubtitle>
          </IntervalSelect>
        </IntervalSelectRow>
        <FlexWrap>
          <Checkbox disabled={level !== Level.day} checked={viewType === ViewType.Min} onChange={handleViewTypeSelect}>
            Päivän pienin tuntikulutus
          </Checkbox>
          <Tooltip
            title={enableTooltip && "Lukitse kuvaaja nykyhetkeen. Lukemat päivittyvät joka tapauksessa automaattisesti"}
          >
            <Checkbox checked={lockToNow} onChange={() => setLockToNow(!lockToNow)}>
              Nyt
            </Checkbox>
          </Tooltip>
        </FlexWrap>
      </LessThan460>
    </Size>
  );
};

const IntervalSelect = styled.div`
  position: relative;
`;

const UnitSubtitle = styled.div`
  position: absolute;
  right: 0;
  bottom: -20px;
`;

const MoreThan460 = styled.div`
  @container toolbar (width < 460px) {
    display: none;
  }
`;

const LessThan460 = styled.div`
  @container toolbar (width >= 460px) {
    display: none;
  }
`;

const MoreThan520 = styled.div`
  @container toolbar (width < 520px) {
    display: none;
  }
`;

const Size = styled.div`
  min-height: 42px;
  container-name: toolbar;
  container-type: inline-size;
`;

const FlexWrap = styled.div`
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
`;

const IntervalSelectRow = styled.div`
  height: 42px;
`;
