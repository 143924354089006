import { useCancelSensorAdjustmentMutation, useGetSensorAdjustmentQuery } from "../../store/api";
import { Sensor } from "../../store/types";
import { Button, Descriptions, Modal, Typography } from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import { useCallback, useMemo } from "react";
import { DateTime } from "luxon";

export const SensorValueAdjustExisting = ({ sensor, onClose }: { sensor: Sensor; onClose: () => void }) => {
  const sensorAdjustment = useGetSensorAdjustmentQuery({ deviceId: sensor.deviceId, key: sensor.key });
  const [cancelSensorAdjustment] = useCancelSensorAdjustmentMutation();

  const date = useMemo(() => {
    if (!sensorAdjustment.data?.timestamp) {
      return "";
    }
    return DateTime.fromISO(sensorAdjustment.data.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
  }, [sensorAdjustment.data?.timestamp]);

  const handleCancel = useCallback(() => {
    cancelSensorAdjustment({ deviceId: sensor.deviceId, key: sensor.key });
  }, [cancelSensorAdjustment, sensor.deviceId, sensor.key]);

  return (
    <Modal
      onCancel={onClose}
      onClose={onClose}
      open
      width={600}
      footer={
        <>
          <Button color={"danger"} variant={"outlined"} onClick={handleCancel}>
            Peru muutos
          </Button>
          <Button type={"primary"} onClick={onClose}>
            OK
          </Button>
        </>
      }
    >
      <Typography.Title level={5}>Lukema on asetettu</Typography.Title>
      <Typography.Text>
        Toimeenpano odottaa laitteen yhteydenottoa. Muutos voidaan perua, mikäli laite ei ole vielä ottanut yhteyttä.
      </Typography.Text>
      <Descriptions column={1} layout={"horizontal"} size={"small"} style={{ marginTop: "32px" }}>
        <DescriptionsItem label={"Aika"}>{date}</DescriptionsItem>
        <DescriptionsItem label={"Mittarilukema"}>{sensorAdjustment.data?.value}</DescriptionsItem>
      </Descriptions>
    </Modal>
  );
};
